import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import commonDe from '../locales/language-de.json'
import commonEn from '../locales/language-en.json'
import commonIt from '../locales/language-it.json'

const resources = {
  de: { common: commonDe },
  en: { common: commonEn },
  it: { common: commonIt },
}

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
    .init({
      resources: resources,
      lng: 'it',
      ns: ['common'],
      defaultNS: 'common',
      fallbackLng: 'en',
      supportedLngs: ['it', 'en', 'de',],
      interpolation: {
        escapeValue: false,
      },
      debug: true,
    });

export default i18next;