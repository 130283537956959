import { Navigate } from "react-router-dom";
import { useStores } from "../../../stores/store";
import { Container } from "../container";

const AdminRoute = ({ Component }) => {
	const { auth } = useStores();

    return auth.isAdmin() ? 
        <Container>
            <Component />
        </Container>
    :
        <Navigate to="/page401" />;
};
export default AdminRoute;