import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import HashLoader from "react-spinners/HashLoader";
import { useTranslation } from "react-i18next";

export default function AdminCountryEditPage() {
    const { t } = useTranslation();

    const { auth } = useStores();
    const { id } = useParams();
    let navigate = useNavigate();

    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [country, setCountry] = useState(null);

    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    useEffect(() => {
        reset();

        if (id != "new") {
            setIsDataLoading(true);
            setIsDataError(false);
        
            auth.getCountry(id).then((res) => {
                setCountry(res.data);
                setIsDataLoading(false);
            }).catch((error) => {
                setIsDataLoading(false);
                setIsDataError(true);
            });
        }
    }, [])

    useEffect(() => {
        setValue('name', country?.name, { shouldValidate: true })
        setValue('priority', country?.priority, { shouldValidate: true })
    }, [country]);

    const postData = (data) => {
        if (id != "new")
            auth.editCountry(id, data);
        else
            auth.addCountry(data);

        navigate(`/admin/countries/`);
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row justify-center">
                {id == "new" ? <span className="text-4xl">{t('new')}</span> : <span className="text-4xl">{t('modify')}</span>}
            </div>
            <div className="w-full flex flex-row justify-center">
                <div className="w-1/3 bg-white">
                    <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('name')}</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                            {errors.nameRequired && <span>{t('fieldMandatory')}</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('priority')}</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("priority", { required: true })} />
                            {errors.priorityRequired && <span>{t('fieldMandatory')}</span>}
                        </div>
                
                        <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                        <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/countries')}>{t('cancel')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
