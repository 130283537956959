import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/store';
import { useEffect, useState } from 'react';
import { parseLicenseJson } from '../../common/functions';
import Moment from 'moment';
import React from 'react';
import { Table } from '../../common/table';

function SchoolCodesPage(props) {
	const { auth } = useStores();
    const { t, i18n } = useTranslation();

	const [error, setError] = useState<string>("");
    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [codes, setCodes] = useState([]);

    useEffect(() => {
        setIsDataLoading(true);
        setIsDataError(false);
    
        auth.getCodes("not-free", null).then((res) => {
            setCodes(res.data);
            setIsDataLoading(false);
          }).catch((error) => {
            setIsDataLoading(false);
            setIsDataError(true);
          });
    }, [])

    const columns = React.useMemo(
        () => [
        {
            Header: t('codes'),
            columns: [
              {
                  Header: t('code'),
                  accessor: 'code',
              },
              {
                Header: t('activationDate'),
                accessor: "activation_date",
                Cell: ({ cell }) => (
                  cell.row.values.activation_date ? Moment(cell.row.values.activation_date).format('DD/MM/yyyy') : t('notActivated')
                )
              },
              {
                Header: t('validity'),
                accessor: "validity_year",
                Cell: props => {
                  return props.value == 1000 ? "Lifetime" : props.value + " " + t('years')
                }
              },
              {
                Header: t('type'),
                accessor: "type",
                Cell: props => {
                  return props.value == 1 ? "Pinguin" : "Miri"
                }
              },
              {
                Header: t('details'),
                accessor: "license_data",
                Cell: props => {
                  return parseLicenseJson(props.value)
                }
              },
            ],
        },
      ],
        []
    );

	return (
		<div className="grid place-items-center">
			<div className="w-full flex flex-col items-center justify-center">

                {!isDataError && !isDataLoading && codes.length > 0 && <Table columns={columns} data={codes} /> }
			</div>
		</div>
	)
}

export default SchoolCodesPage;