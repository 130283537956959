import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import { SketchPicker } from 'react-color';
import HashLoader from "react-spinners/HashLoader";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";

export default function AdminEducationLevelEditPage() {
    const { t } = useTranslation();

    const { auth } = useStores();
    const { id } = useParams();
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);
    const [color, setColor] = useState();
    const [hexColor, setHexColor] = useState("#FFFFFF");
    const [currentCountryId, setCurrentCountryId] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [educationLevel, setEducationLevel] = useState(null);

    useEffect(() => {
        reset();
        let countryId = searchParams.get('country');
        setCurrentCountryId(countryId);

        if (id != "new") {
            setIsDataLoading(true);
            setIsDataError(false);
        
            auth.getEducationLevel(countryId, id).then((res) => {
                setEducationLevel(res.data);
                setIsDataLoading(false);
            }).catch((error) => {
                setIsDataLoading(false);
                setIsDataError(true);
            });
        } else {
            setIsDataLoading(false);
        }
    }, [])

    useEffect(() => {
        setValue('name', educationLevel?.name, { shouldValidate: true })
        setValue('description', educationLevel?.description, { shouldValidate: true })
        setValue('priority', educationLevel?.priority, { shouldValidate: true })
        setColor(educationLevel?.color);
        setHexColor(educationLevel?.color);
    }, [educationLevel]);

    const postData = (data) => {
        data.fk_country = currentCountryId;
        data.color = hexColor;
        let sp = searchParams.get('parent_id');
        if (sp != null && sp != "null")
            data.parent_level = sp;

        if (id != "new")
            auth.editEducationLevel(currentCountryId, id, data);
        else
            auth.addEducationLevel(currentCountryId, data);

        navigate(`/admin/education-levels/?parent_id=${sp}`);
    }

    const handleChange = color => {
        setColor(color);
        setHexColor(color.hex);
    }

    const doDelete = () => {
        auth.delEducationLevel(currentCountryId, id).then(() => {
            navigate(`/admin/education-levels?parent_id=${searchParams.get('parent_id')}`);
        });
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    return (
        <div className="w-full flex flex-col">

            <Popup open={showDelete}
                position="right center"
                closeOnDocumentClick
            >
                <div className="flex flex-col items-center gap-6 p-6">
                    <span>{t('confirmDelete')}</span>
                    <div className="flex flex-row items-center gap-6">
                        <button onClick={(e) => { e.preventDefault(); doDelete() }} className="bg-primary text-white font-bold py-2 px-4 rounded-full">{t('yes')}</button>
                        <button onClick={(e) => { e.preventDefault(); setShowDelete(false) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('no')}</button>
                    </div>
                </div>
            </Popup>

            <div className="flex flex-row justify-center">
                {id == "new" ? <span className="text-4xl">{t('new')}</span> : <span className="text-4xl">{t('modify')}</span>}
            </div>
            <div className="w-full flex flex-row justify-center">
                <div className="w-1/3 bg-white">
                    <div className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl">
                        <form className="w-full bg-white flex flex-col gap-4 p-8" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">{t('name')}</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                                {errors.nameRequired && <span>{t('fieldMandatory')}</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">{t('description')}</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("description", { required: true })} />
                                {errors.descriptionRequired && <span>{t('fieldMandatory')}</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">{t('priority')}</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("priority", { required: true })} />
                                {errors.priorityRequired && <span>{t('fieldMandatory')}</span>}
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">{t('color')}</label>
                                <SketchPicker color={color} onChangeComplete={handleChange} />
                                {errors.colorRequired && <span>{t('fieldMandatory')}</span>}
                            </div>
                    
                            <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                            <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate(`/admin/education-levels?parent_id=${searchParams.get('parent_id')}`)}>{t('cancel')}</button>
                        </form>
                        {id !== "new" && <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => setShowDelete(true)}>{t('delete')}</button> }
                    </div>
                </div>
            </div>
        </div>
    );
}
