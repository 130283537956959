import React, { useEffect, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FaArrowRight, FaEdit, FaTrash } from 'react-icons/fa';
import HashLoader from 'react-spinners/HashLoader';
import { getElementById } from '../../common/functions';
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';

function AdminEducationLevelsPage() {
  const { t } = useTranslation();

  const { auth } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentParentId, setCurrentParentId] = useState(null);
  const [countries, setCountries] = useState(null);
  const [educationLevels, setEducationLevels] = useState([]);
  const [lessonGroups, setLessonGroups] = useState([]);
  const [history, setHistory] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    setIsFilterLoading(true);
    setIsFilterError(false);

    setCurrentParentId(searchParams.get('parent_id'));
    if (searchParams.get('returned_from_select') != null) {
      let history = localStorage.getItem('educationLevels_history')

      if (history != null) {
        setHistory(JSON.parse(history));
      }
  
    }

    auth.getCountries().then((res) => {
      setCountries(res.data);
      if (res.data.length > 0) {
        let filterCountry = localStorage.getItem('educationLevels_countryKey');
        if (filterCountry !== undefined && filterCountry != null) {
          setCurrentCountry(getElementById(res.data, filterCountry));
        } else {
          setCurrentCountry(res.data[0]);
        }
      }
      setIsFilterLoading(false);
    }).catch((error) => {
      setIsFilterLoading(false);
      setIsFilterError(true);
    });
  }, [])

  useEffect(() => {
    if (currentCountry == null)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    auth.getEducationLevels(currentCountry.id, currentParentId).then((res) => {
      setEducationLevels(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }, [currentCountry, currentParentId])

  const getEducationLevel = () => {
    auth.getEducationLevel(currentCountry?.id, currentParentId).then((res) => {
      setLessonGroups(res.data.lesson_groups);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });

  }

  useEffect(() => {
    if (currentParentId === null)
      return;
  
    if (educationLevels !== null && educationLevels.length > 0)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    getEducationLevel();
  }, [educationLevels])

  const handleCountryChange = ({ target }) => {
    localStorage.setItem('educationLevels_countryKey', target.value);
    let country = getElementById(countries, target.value);
    setHistory([]);
    setLessonGroups([]);
    setCurrentCountry(country);
    setCurrentParentId(null);
  }

  const handleDrillDown = ({id, name}) => {
    setHistory([...history, {id: id, name: name}]);
    setCurrentParentId(id);
  }

  const handleBack = () => {
    setLessonGroups([]);
    let tempHistory = history.slice(0, -1);
    setHistory(tempHistory);

    if (tempHistory.length > 0)
      setCurrentParentId(tempHistory[tempHistory.length - 1].id);
    else
      setCurrentParentId(null);
  }

  const doRemoveLesson = async (id) => {
    await auth.removeLessonGroupsFromEducationLevel(currentCountry?.id, currentParentId, id);
    getEducationLevel();
  }

  const addNewLesson = () => {
    localStorage.setItem('educationLevels_history', JSON.stringify(history));
    navigate(`/admin/lesson-groups/?referral_id=${currentParentId}`);
  }

  const educationLevelColumns = [
      {
          Header: t('education'),
          columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/education-levels/${cell.row.values.id}/?country=${currentCountry?.id}&parent_id=${currentParentId}`}>
                <FaEdit />
              </Link>
            )
          },
          {
            Header: t('folder'),
            accessor: 'fk_country_name',
        },
          {
              Header: t('name'),
              accessor: 'name',
          },
          {
            Header: t('description'),
            accessor: 'description',
          },
          {
            Header: t('priority'),
            accessor: 'priority',
          },
          {
            Header: t('color'),
            accessor: 'color',
            Cell: ({ cell }) => (
              <span className="border border-primary py-1 px-3" style={{backgroundColor: cell.row.values.color}}></span>
            )
        },
          {
            Header: t('enter'),
            Cell: ({ cell }) => (
              <button onClick={() => handleDrillDown(cell.row.values)}>
                <FaArrowRight />
              </button>
            )
          },
        ],
      }
    ];

  const lessonGroupColumns = 
    [
    {
        Header: t('lessons'),
        columns: [
        {
            Header: t('name'),
            accessor: 'name',
        },
        {
          Header: t('description'),
          accessor: 'description',
        },
        {
          Header: t('screenRatio'),
          accessor: 'screen_ratio',
        },
        {
          Header: t('delete'),
          accessor: "id",
          Cell: ({ cell }) => (
            <FaTrash onClick={() => doRemoveLesson(cell.row.values.id)} className='cursor-pointer' />
          )
        },
      ],
    },
    ];

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-start">
        <span className="text-4xl">{t('levels')}</span>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <select onChange={handleCountryChange} value={currentCountry?.id} className="bg-primary text-white rounded-full p-4 cursor-pointer">
            { countries?.map(({ id, name }, index) => <option key={id} value={id} >{name}</option>) }
          </select>
          { history?.map(({ id, name }, index) => <span className='mx-1 border-2 border-primary rounded-full p-2'>{name}</span>) }
          { history.length > 0 && <button onClick={handleBack} className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full">{t('back')}</button> }
        </div>
        { lessonGroups.length == 0 && <Link className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" to={`/admin/education-levels/new?parent_id=${currentParentId}&country=${currentCountry?.id}`}>{t('addLevel')}</Link> }
      </div>

      {isDataLoading && <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div> }

      {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

      {lessonGroups.length == 0 && !isDataError && !isDataLoading && <Table columns={educationLevelColumns} data={educationLevels} /> }
      {!isDataError && !isDataLoading && educationLevels.length == 0 && currentParentId != undefined &&
        <div className='flex flex-col'>
          <div className='flex flex-row justify-end'>
            <button className="bg-primary text-white font-bold p-4 rounded-full" onClick={addNewLesson}>{t('addLesson')}</button>
          </div>
          <Table columns={lessonGroupColumns} data={lessonGroups} />
        </div>
      }
    </div>
  )
}

export default AdminEducationLevelsPage
