import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from "../../../../stores/store";
import HashLoader from "react-spinners/HashLoader";
import { useTranslation } from "react-i18next";

export default function AdminNewLessonGroupPage() {
    const { t } = useTranslation();

    const { auth } = useStores();
    const params = useParams();

    const [countries, setCountries] = useState([]);
    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [lessonGroup, setLessonGroup] = useState(null);

    let navigate = useNavigate();
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    useEffect(() => {
        reset();
        getCountries();

        if (params.id != "new") {
            setIsDataLoading(true);
            setIsDataError(false);
        
            auth.getLessonGroup(params.id).then((res) => {
                setLessonGroup(res.data);
                setIsDataLoading(false);
            }).catch((error) => {
                setIsDataLoading(false);
                setIsDataError(true);
            });
        }
    }, []);

    useEffect(() => {
        setValue('name', lessonGroup?.name, { shouldValidate: true })
        setValue('description', lessonGroup?.description, { shouldValidate: true })
        setValue('priority', lessonGroup?.priority, { shouldValidate: true })
        setValue('fk_country', lessonGroup?.fk_country, { shouldValidate: true })
        setValue('is_completed', lessonGroup?.is_completed, { shouldValidate: true })
        setValue('is_demo_enabled', lessonGroup?.is_demo_enabled, { shouldValidate: true })
    }, [lessonGroup]);

    const getCountries = () => {
        auth.getCountries().then(res => {
            setCountries(res.data);
        }).catch((error) => {
            setIsDataError(true);
        });
    }

    const postData = async (data) => {
        const formData = new FormData();
        if (params.id != "new") {
            await auth.editLessonGroup(params.id, data);
        } else {
            await auth.addLessonGroup(data);
        }

        navigate(`/admin/lesson-groups/`);
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>


    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-center">
                {params.id == "new" ? <span className="text-4xl">{t('new')}</span> : <span className="text-4xl">{t('modify')}</span>}
            </div>
            <div className="w-full flex flex-row justify-center">
                <div className="bg-white w-1/3">
                    <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('name')}</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: true })} />
                            {errors.nameRequired && <span>{t('fieldMandatory')}</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('description')}</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("description", { required: true })} />
                            {errors.descriptionRequired && <span>{t('fieldMandatory')}</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('priority')}</label>
                            <input {...register("priority")} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                        <div className="flex flex-col">
                            <select {...register("fk_country", {})} className="bg-white p-4 mx-2 cursor-pointer">
                                {countries?.map(({ id, name }, index) => <option value={id} >{name}</option>)}
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('completed')}?</label>
                            <input type="checkbox" {...register("is_completed")} />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Demo?</label>
                            <input type="checkbox" {...register("is_demo_enabled")} />
                        </div>

                        <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                        <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/lesson-groups')}>{t('cancel')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
