import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { FaEdit } from 'react-icons/fa';
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';

function SchoolUsersPage() {
  const { auth } = useStores();
  const { t, i18n } = useTranslation();

  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    searchKey.current = localStorage.getItem('school_users_searchKey');
    getUsers();
  }, [])

  const getUsers = () => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getUsers(2).then((res) => {
      setUsers(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }
    
  const columns = React.useMemo(
      () => [
      {
          Header: t('users'),
          columns: [
            {
              Header: t('modify'),
              accessor: "id",
              Cell: ({ cell }) => (
                <Link to={
                    `/school/users/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: t('companyName'),
                accessor: 'surname',
            },
          ],
      },
      {
        Header: "Login",
        columns: [
        {
            Header: t('email'),
            accessor: 'email',
        },
        {
          Header: t('type'),
          accessor: 'type',
          Cell: ({ cell }) => (
            cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
            cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
            cell.row.values.type == 3 ? <span>{t('school')}</span> : 
            cell.row.values.type == 4 ? <span>{t('demo')}</span> :
            cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
          )
        },
        {
          Header: t('active'),
          accessor: "is_old",
          Cell: ({ cell }) => (
            cell.row.values.is_old ? t('no') : t('yes')
          )
        },
        ],
    },
    ],
      []
  );

  if (isDataLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isDataError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div className="flex flex-col px-12">
      <div className="flex flex-row justify-between items-center">
          <span className="text-4xl">{t('users')}</span>
          <Link className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full mt-4" to="/school/users/new">{t('add')}</Link>
      </div>

      <Table columns={columns} data={users} />
    </div>
  )
}

export default SchoolUsersPage;
