import { useEffect, useState } from 'react';
import { AdminHeader } from './adminHeader';
import { SchoolHeader } from './schoolHeader';
import { observer } from 'mobx-react-lite';
import { PartnerHeader } from './partnerHeader';
import { useLocation } from 'react-router-dom';
import { useStores } from '../../../stores/store';

export const Header = observer((props: any) => {
    const { auth } = useStores();
    const location = useLocation();
    const [userAccessLevel, setUserAccessLevel] = useState(null);

    useEffect(() => {
        setUserAccessLevel(auth.profile?.type || null);
    }, [auth.profile])

    useEffect(() => {
        /* Location changed */
      }, [location]);

    if (window.location.href.includes('/common/composer'))
        return <></>;

    if (userAccessLevel == 1)
        return <AdminHeader />

    if (userAccessLevel == 3)
        return <SchoolHeader />

    if (userAccessLevel == 5)
        return <PartnerHeader />

    return <></>;
});
