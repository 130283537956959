import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import {CSVLink } from 'react-csv';
import HashLoader from 'react-spinners/HashLoader';

import Moment from 'moment';
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';
import { parseLicenseJson } from '../../common/functions';

function AdminCodesPage() {
  const { t } = useTranslation();

  const { auth } = useStores();

  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [codes, setCodes] = useState([]);

  const [resetCodeId, setResetCodeId] = useState<number>(0);
  const filterValue = useRef<string>("free");
  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  useEffect(() => {
    getCodes();
  }, [])

  const getCodes = () => {
    setIsDataLoading(true);
    setIsDataError(false);

    auth.getCodes(filterValue.current, searchKey.current).then((res) => {
      setCodes(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });

  }

  const columns = React.useMemo(
      () => [
      {
          Header: t('codes'),
          columns: [
            {
              Header: t('modify'),
              accessor: "id",
              Cell: ({ cell }) => (
                <Link to={
                    `/admin/codes/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: t('code'),
                accessor: 'code',
            },
            {
              Header: t('generationDate'),
              accessor: "generation_date",
              Cell: ({ cell }) => (
                Moment(cell.row.values.generation_date).format('DD/MM/yyyy')
              )
            },
            {
              Header: t('activationDate'),
              accessor: "activation_date",
              Cell: ({ cell }) => (
                cell.row.values.activation_date ? Moment(cell.row.values.activation_date).format('DD/MM/yyyy') : t('notActivated')
              )
            },
            {
              Header: t('validity'),
              accessor: "validity_year",
              Cell: props => {
                return props.value == 1000 ? "Lifetime" : props.value + " " + t('years')
              }
            },
            {
              Header: t('type'),
              accessor: "type",
              Cell: props => {
                return props.value == 1 ? "Pinguin" : "Miri"
              }
            },
            {
              Header: t('school'),
              accessor: "school_name",
              Cell: props => {
                if (props.value == null || props.value.length == 0) {
                  if (props.row.values.activation_date != null) {
                    return "";
                  }
                  return <Link className="border-2 border-primary rounded-full p-2" to={`/admin/schools/?referral_id=${props.row.values.id}`}>
                    {t('assign')}
                  </Link>
                } else {
                  return props.value
                }
              }
            },
            {
              Header: t('mechanographicCode'),
              accessor: "school_code",
            },
            {
              Header: t('details'),
              accessor: "license_data",
              Cell: props => {
                return parseLicenseJson(props.value)
              }
            },
            {
              Header: t('note'),
              accessor: "notes",
            },
            {
              Header: t('productionLot'),
              accessor: "production_lot",
            },
            {
              Header: t('commercialAgent'),
              accessor: "commercial_agent",
            },
            {
              Header: t('reset'),
              Cell: props => (
                props.row.values.activation_date ? <button className='bg-primary rounded-3xl p-3' onClick={() => setResetCodeId(props.row.values.id)}>Reset</button> : <span></span>
              )
            },
          ],
      },
    ],
      []
  );

  const handleFilterChange = e => {
    filterValue.current = e.target.value;
    getCodes();
  }

  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    searchKey.current = event.target.value;
    getCodes();
  }

  const clear = () => {
    searchKey.current = null;
    searchRef.current.value = "";
    getCodes();
  }

  const handleSubmitReset = async () => {
    await auth.resetCode(resetCodeId);
    getCodes();
  }

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div className="w-full">
      <Popup open={resetCodeId != 0}
        position="right center"
        closeOnDocumentClick>

        <form onSubmit={handleSubmitReset} className="w-full flex flex-col items-center gap-6 m-4">
          {t('confirmResetActivation')}

          <div className="w-full flex flex-row justify-center gap-6">
            <button className="bg-primary text-white font-bold py-2 px-4 rounded-full" type="submit">{t('yes')}</button>
            <button onClick={(e) => { e.preventDefault(); setResetCodeId(0) }} className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full">{t('no')}</button>
          </div>
        </form>
      </Popup>

      <div className="flex flex-col">
        <div className="flex flex-row justify-start">
          <span className="text-4xl">{t('activationCodes')}</span>
        </div>
        <div className='flex flex-row mt-4 justify-between'>
          <div className='flex flex-row mt-4 space-x-2'>
            <select className="bg-primary text-white rounded-full p-2 cursor-pointer" onChange={handleFilterChange}>
              <option value="free">{t('free')}</option>
              <option value="used">{t('used')}</option>
            </select>
            <input onChange={search} ref={searchRef} placeholder={t('searchMin4Chars')} className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            <button onClick={clear} className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full mr-4">{t('delete')}</button>
            <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full mr-4" data={codes} filename={`exportdata_${Moment().format("DD-MM-YYYY")}.csv`}>{t('export')}</CSVLink>
          </div>
          <Link to={'/admin/codes/generate'} className='bg-primary flex flex-row items-center p-4 rounded-full font-bold text-white'>{t('generateCodes')}</Link>
        </div>
        {isDataLoading && <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div> }

        {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

        {!isDataError && !isDataLoading && <Table columns={columns} data={codes} /> }
      </div>
    </div>
  )
}

export default AdminCodesPage
