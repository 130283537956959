import { useStores } from '../../stores/store';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { config } from "../../config";
import { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { FaArrowLeft } from 'react-icons/fa6';
import { halve } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

export default function EducationLevelSelectionPage() {
	const { auth } = useStores();
	const { t, i18n } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();

	let navigate = useNavigate();
	let country = auth.getLocalCountry();
	const [educationLevel, setEducationLevel] = useState(auth.getLocalEducationLevel());

    const touchEnabled = useRef<boolean>(false);
	const [educationLevels, setEducationLevels] = useState([]);
	const [helpPopupShown, setHelpPopupShown] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
	}, [])

	const getEducationLevel = () => {
		auth.getEducationLevels(country.id, educationLevel?.id).then((res) => {
			setEducationLevels(res.data);
		}).catch((error) => {
		});
	}

	useEffect(() => {
		getEducationLevel();
	}, [educationLevel])

	const goToPersonalArea = () => {
		navigate(`/user/personal-area`);
	}

	const goToEducationLevel = localEducationLevel => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.pushLocalEducationLevelHistory(educationLevel);
		auth.saveLocalEducationLevel(localEducationLevel);

		if (localEducationLevel.lesson_groups.length == 0) {
			setEducationLevel(localEducationLevel);
		} else {
			navigate(`/user/lesson-group-selection`);
		}
	}

	const back = () => {
		let educationLevel = auth.popLocalEducationLevelHistory();
		if (educationLevel == null) {
			auth.saveLocalEducationLevel(null);
			setEducationLevel(null);
		} else if (educationLevel.id == 'country') {
			navigate(`/user/country-selection`);
		} else {
			auth.saveLocalEducationLevel(educationLevel);
			setEducationLevel(educationLevel);
		}
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<Popup open={helpPopupShown}
				position="right center"
			>
				<div className="flex flex-col items-center gap-6 p-6">
					<div>{t('helpDisableSensors')}</div>
					<div>{t('helpAutomaticCalibration')}</div>
					<div>{t('helpGoBack')}</div>
					<div>{t('helpCloseApp')}</div>
					<button className="bg-primary p-4 mx-2 rounded-lg text-white text-md text-center" onMouseDown={() => setHelpPopupShown(!helpPopupShown)} onTouchStart={() => config.isMiri() ? setHelpPopupShown(!helpPopupShown) : null}>Chiudi</button>
				</div>
			</Popup>

			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>
			{ educationLevels.length > 0 &&
				<div className="flex flex-col items-center justify-center">
					<div className="bg-primary rounded-t-2xl p-2 text-white text-3xl w-3/4 text-center">{ auth.getLocalEducationLevel() != null ? auth.getLocalEducationLevel().name : auth.getLocalCountry()?.name }</div>
						<div className="w-full bg-white border-2 border-primary rounded-t-lg p-2 shadow-2xl">
						{ educationLevels.length > 5 ?
							<div className="w-full flex items-center">
								<div className='w-1/2 flex flex-col items-center justify-start mb-8 mr-8 ml-8'>
									{halve(educationLevels, true).map((educationLevel) => (
										<button onMouseDown={() => goToEducationLevel(educationLevel)} onTouchStart={() => config.isMiri() ? goToEducationLevel(educationLevel) : null} key={educationLevel.id} style={{ backgroundColor: educationLevel.color }} className="text-white text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{educationLevel.name}</button>
									))}
								</div>
								<div className='w-1/2 flex flex-col items-center justify-start mb-8 mr-8 ml-8'>
									{halve(educationLevels, false).map((educationLevel) => (
										<button onMouseDown={() => goToEducationLevel(educationLevel)} onTouchStart={() => config.isMiri() ? goToEducationLevel(educationLevel) : null} key={educationLevel.id} style={{ backgroundColor: educationLevel.color }} className="text-white text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{educationLevel.name}</button>
									))}
								</div>
							</div>
						:
							<div className="flex items-center">
								<div className='flex flex-col items-center justify-start mb-8 mr-8 ml-8'>
									{educationLevels.map((educationLevel) => (
										<button onMouseDown={() => goToEducationLevel(educationLevel)} onTouchStart={() => config.isMiri() ? goToEducationLevel(educationLevel) : null} key={educationLevel.id} style={{ backgroundColor: educationLevel.color }} className="text-white text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{educationLevel.name}</button>
									))}
								</div>
							</div>
						}
						</div>
					<div className="flex justify-between items-start gap-1 w-full">
						<div onMouseDown={back} onTouchStart={() => config.isMiri() ? back() : null} className='bg-primary p-4 rounded-b-lg text-white text-4xl text-center cursor-pointer'>
							<FaArrowLeft />
						</div>
						<div className="flex justify-end items-start gap-1 w-full">
							{ config.isMiri() &&
								<button onMouseDown={() => setHelpPopupShown(!helpPopupShown)} onTouchStart={() => config.isMiri() ? setHelpPopupShown(!helpPopupShown) : null} className='bg-primary p-4 mx-2 rounded-b-lg text-white text-2xl text-center uppercase'>
									{t('help')}
								</button>
							}
							<Link to="#" className='bg-primary p-4 rounded-b-lg text-white text-2xl text-center uppercase'>
								{t('credits')}
							</Link>
						</div>
					</div>
				</div>
			}
		</div>
	)
}
