import React from "react"

function Page404(props) {
	return (
		<div className="grid h-screen place-items-center w-full">
			404
		</div>
	)
}

export default Page404;