import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from "../../../stores/store";
import HashLoader from "react-spinners/HashLoader";
import { useTranslation } from "react-i18next";

export default function AdminCodeEditPage() {
    const { t } = useTranslation();

    const { auth } = useStores();
    const { id } = useParams();
    let navigate = useNavigate();

    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [code, setCode] = useState(null);

    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data => postData(data);

    useEffect(() => {
        reset();

        if (id != "new") {
            setIsDataLoading(true);
            setIsDataError(false);
        
            auth.getCode(id).then((res) => {
                setCode(res.data);
                setIsDataLoading(false);
            }).catch((error) => {
                setIsDataLoading(false);
                setIsDataError(true);
            });
        }
    }, [])

    useEffect(() => {
        setValue('code', code?.code, { shouldValidate: true })
        setValue('validity_year', code?.validity_year, { shouldValidate: true })
        setValue('production_lot', code?.production_lot, { shouldValidate: true })
        setValue('notes', code?.notes, { shouldValidate: true })
        setValue('commercial_agent', code?.commercial_agent, { shouldValidate: true })
        setValue('suspended', code?.suspended, { shouldValidate: true })
    }, [code]);

    const postData = (data) => {
        if (id != "new")
            auth.editCode(id, data);
        else
            auth.addCode(data);

        navigate(`/admin/codes/`);
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row justify-center">
                {id == "new" ? <span className="text-4xl">{t('new')}</span> : <span className="text-4xl">{t('new')}</span>}
            </div>
            <div className="w-full flex flex-row justify-center">
                <div className="w-1/3 bg-white">
                    <form className="w-full bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('code')}</label>
                            <input disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("code", { required: true })} />
                            {errors.codeRequired && <span>{t('fieldMandatory')}</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold">{t('type')}</label>
                            <select className="border rounded p-2" {...register("type", { required: true })}>
                                <option value="1">Pinguin</option>
                                <option value="2">Miri</option>
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('validity')}</label>
                            <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("validity_year", { required: true })}>
                                <option value="1">1 {t('year')}</option>
                                <option value="2">2 {t('years')}</option>
                                <option value="3">3 {t('years')}</option>
                                <option value="4">4 {t('years')}</option>
                                <option value="5">5 {t('years')}</option>
                                <option value="1000">Lifetime</option>
                            </select>
                            {errors.validity_yearRequired && <span>{t('fieldMandatory')}</span>}
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('productionLot')}</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("production_lot")} />
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('note')}</label>
                            <textarea rows={5} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("notes")}></textarea>
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('agent')}</label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("commercial_agent")} />
                        </div>
                        <div className="flex flex-col items-start">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('suspended')}</label>
                            <input type="checkbox" {...register("suspended")} />
                        </div>                
                        <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                        <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/codes')}>{t('cancel')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
