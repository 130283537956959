import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Page401(props) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="w-full grid place-items-center">
			<div className="flex flex-col items-center gap-6 mt-24">
				<span className="text-2xl">{t('notAuthorized')}</span>
				<button onClick={() => navigate(-2)} className="border-2 border-primary rounded-3xl p-4">{t('back')}</button>
			</div>
		</div>
	)
}

export default Page401;