import { useStores } from '../../stores/store';
import { Link, useNavigate } from 'react-router-dom';
import { config } from "../../config";
import { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';

export default function CountrySelectionPage() {
	const { t, i18n } = useTranslation();
	const { auth } = useStores();
	let navigate = useNavigate();
    const touchEnabled = useRef<boolean>(false);
	const [helpPopupShown, setHelpPopupShown] = useState<boolean>(false);
	const [countries, setCountries] = useState([]);
	const [isDataError, setIsDataError] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		auth.getCountries().then((res) => {
			setCountries(res.data);
			setIsDataLoading(false);

		}).catch((error) => {
		  setIsDataLoading(false);
		  setIsDataError(true);
		});

	}, [])

	const goToEducationLevel = country => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

		auth.saveLocalEducationLevel(null);
		auth.saveLocalCountry(country)

		auth.clearLocalEducationLevelHistory();
		auth.pushLocalEducationLevelHistory({'id': 'country'});
		
		navigate(`/user/education-level-selection/`);
	}

	const goToPersonalArea = () => {
		auth.clearLocalEducationLevel();
		auth.clearLocalLessonGroup();
		auth.clearLocalLesson();
		navigate(`/user/personal-area`);
	}

	return (
		<div className="grid h-screen place-items-center w-full">
			<Popup open={helpPopupShown}
				position="right center"
			>
				<div className="flex flex-col items-center gap-6 p-6">
					<div>{t('helpDisableSensors')}</div>
					<div>{t('helpAutomaticCalibration')}</div>
					<div>{t('helpGoBack')}</div>
					<div>{t('helpCloseApp')}</div>
					<button className="bg-primary p-4 mx-2 rounded-lg text-white text-md text-center" onMouseDown={() => setHelpPopupShown(!helpPopupShown)} onTouchStart={() => config.isMiri() ? setHelpPopupShown(!helpPopupShown) : null}>Chiudi</button>
				</div>
			</Popup>

			<video className="absolute -z-10 w-full h-full object-fill" autoPlay loop>
				{ config.isMiri() ?
						<source src="/video/background_miri.mp4" type="video/mp4" />
					:
						<source src="/video/background_pinguin.mp4" type="video/mp4" />
				}
			</video>

			{ countries.length > 0 &&
				<div className="flex flex-col items-center justify-center">
					<div className="bg-white border-2 border-primary rounded-t-lg p-2 shadow-2xl">
						<div className="flex items-center">
							<div className='flex flex-col items-center justify-start mb-8 mr-8 ml-8'>
								{countries.map((country) => (
									<button onMouseDown={() => goToEducationLevel(country)} onTouchStart={() => config.isMiri() ? goToEducationLevel(country) : null} key={country.id} className="text-white bg-primary text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{country.name}</button>
								))}
								<button onMouseDown={() => goToPersonalArea()} onTouchStart={() => config.isMiri() ? goToPersonalArea() : null} className="border-2 border-primary text-primary text-4xl rounded-lg p-8 m-8 w-full whitespace-nowrap mb-1">{t('personalArea')}</button>
							</div>
						</div>
					</div>
					<div className="flex justify-between items-start gap-1 w-full">
						<div onMouseDown={() => navigate("/")} onTouchStart={() => config.isMiri() ? navigate("/") : null} className='bg-primary p-4 rounded-b-lg text-white text-2xl text-center cursor-pointer uppercase'>
							{t('quit')}
						</div>
						<div className="flex justify-end items-start gap-1 w-full">
							{ false &&
								<button onMouseDown={() => setHelpPopupShown(!helpPopupShown)} onTouchStart={() => config.isMiri() ? setHelpPopupShown(!helpPopupShown) : null} className='bg-primary p-4 mx-2 rounded-b-lg text-white text-2xl text-center uppercase'>
									{t('help')}
								</button>
							}
							<Link to="#" className='bg-primary p-4 rounded-b-lg text-white text-2xl text-center uppercase'>
								{t('credits')}
							</Link>
						</div>
					</div>
				</div>
			}
		</div>
	)
}