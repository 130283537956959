import { useEffect, useState } from 'react'
import { useStores } from '../../../stores/store'
import HashLoader from 'react-spinners/HashLoader';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseLicenseJson } from '../../common/functions';

function AdminSchoolDetailPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  const { auth } = useStores();
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [school, setSchool] = useState(null);

  useEffect(() => {
    auth.getSchool(id).then((res) => {
      setSchool(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }, [])

  if (isDataLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isDataError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
		<div className="w-full flex flex-col p-12 items-start justify-center gap-2">
        <Link className="bg-primary text-white rounded-3xl p-4" to={`/admin/schools/`}>
          {t('back')}
        </Link>

        <div className='flex flex-col'>
            <span className='text-2xl'>{t('accountDetails')}</span>
            <div className="flex flex-col border-2 border-primary rounded-3xl p-4">
                <span className='font-bold'>{t('name')}: { school?.name }</span>
                <span>{t('email')}: { school?.email }</span>
                <span>{t('address')}: { school?.address }</span>
                <span>{t('city')}: { school?.city }</span>
                <span>{t('province')}: { school?.province }</span>
                <span>{t('region')}: { school?.regione }</span>
                <span>{t('code')}: { school?.code }</span>
                <span>{t('user')}:&nbsp;
                  {
                    school?.users?.filter(user => user.type == 3).map((user) =>
                      <span>{user.pretty_name} - {user.email}</span>
                    )
                  }
                </span>
            </div>
        </div>
        <div className='flex flex-col'>
            <span className='text-2xl'>{t('users')}</span>
            <div className="flex flex-col border-2 border-primary rounded-3xl p-4">
            {school?.users?.filter(user => user.type == 2).map((user) => 
                <div>{ user.pretty_name } - {user.email} </div>
              )}
            </div>
        </div>

        <div className='flex flex-col'>
            <span className='text-2xl'>{t('licenses')}</span>
            <div className="flex flex-col border-2 border-primary rounded-3xl p-4">
              {school?.licenses?.map((lic) => 
                <div>{ parseLicenseJson(lic.license_data) }</div>
              )}
            </div>
        </div>
		</div>  
  )
}

export default AdminSchoolDetailPage;
