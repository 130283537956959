import { useStores } from '../stores/store';
import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SearchSchoolPage from './searchSchoolPage';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';

function SignupPage(props) {
	const { t } = useTranslation();
	const { auth } = useStores();
	const [error, setError] = useState<string>("");
	const [typeSelected, setTypeSelected] = useState<number>(0);
	const [showSearchSchool, setShowSearchSchool] = useState<boolean>(false);
	const [passwordType, setPasswordType] = useState('password');
	const [confirmPasswordType, setConfirmPasswordType] = useState('password');
	const currentSchool = useRef<any>(null);
	const { register: registerSchool, handleSubmit: handleSubmitSchool, setValue: setValueSchool, formState: formStateSchool } = useForm();
	const { register: registerIndividual, handleSubmit: handleSubmitIndividual, setValue: setValueIndividual, formState: formStateIndividual } = useForm();
	// eslint-disable-next-line
	const [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();

	const onSubmitSchool = async data => {
		setError("");

		if (data.password !== data.confirmPassword) {
			setError(t('passwordsDoNotMatch'));
			return;
		}

		if (data.password.length < 8) {
			setError(t('passwordAtLeast8Chars'));
			return;
		}

		if (currentSchool.current === null) {
			setError(t('lookupNeeded'));
			return;
		}

		let res = await auth.signup(data.email, data.password, data.code,
			data.activationCode, currentSchool.current.name, currentSchool.current.address, currentSchool.current.city, currentSchool.current.province,
			currentSchool.current.region, data.telephone_number, currentSchool.current.zip_code);

		if (res == null) {
			setError(t('generalError'));
		} else if (!res || res.error) {
			setError(`${t('registrationFailed')}: ${res.msg}`);
		} else {
			if (res.success)
				navigate('/');
			else
				setError(`${t('registrationFailed')}: ${res.msg}`)
		}
	};

	const onSubmitIndividual = async data => {
		setError("");

		if (data.password !== data.confirmPassword) {
			setError(t('passwordsDoNotMatch'));
			return;
		}

		if (data.password.length < 8) {
			setError(t('passwordAtLeast8Chars'));
			return;
		}

		const res = await auth.signup(data.email, data.password,
			null, data.activationCode, data.name, data.address,
			data.city, data.province, data.region, data.telephone_number, data.zip_code);

		if (res == null) {
			setError(t('generalError'));
		} else if (!res || res.error) {
			setError(`${t('registrationFailed')}: ${res.msg}`);
		} else {
			if (res.success)
				navigate('/');
			else
				setError(`${t('registrationFailed')}: ${res.msg}`)
		}
	};

	const handleTogglePassword = () => {
		if (passwordType === 'password'){
			setPasswordType('text')
		} else {
			setPasswordType('password')
		}
	}

	const handleToggleConfirmPassword = () => {
		if (confirmPasswordType === 'password'){
			setConfirmPasswordType('text')
		} else {
			setConfirmPasswordType('password')
		}
	}

	const back = evt => {
		navigate(`/?key=${searchParams.get('key')}`);
	};

	const onSearchedSchool = school => {
		setShowSearchSchool(false);
		currentSchool.current = school;
		setValueSchool('code', school.code, { shouldValidate: true })
	}

	if (typeSelected == 0)
		return <div className="grid h-screen place-items-center">
			<div className='flex flex-col items-center'>
				<span className='text-3xl'>{t('registration')}</span>
				<div className='flex flex-col items-center border-2 border-primary p-12 rounded-3xl'>
					<label className="block text-gray-700 text-sm font-bold mb-2">{t('type')}</label>
					<select onChange={(e) => setTypeSelected(+e.target.value)} className="bg-primary text-white rounded-full p-2 cursor-pointer">
						<option value="0">{t('select')}</option>
						<option value="1">{t('school')}</option>
						<option value="2">{t('other')}</option>
					</select>
				</div>
			</div>
		</div>

	
	if (typeSelected == 1)
		return (
			<div className="grid h-screen place-items-center">
				{showSearchSchool && <SearchSchoolPage onSubmit={onSearchedSchool} onClose={() => setShowSearchSchool(false) }/> }

				{!showSearchSchool && <div className="w-full flex flex-col items-center justify-center">
					<img alt="" src="userIcon.png" />
					<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmitSchool(onSubmitSchool)}>
						<label>{t('serialCode')} *:</label>
						<input {...registerSchool("activationCode", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.activationCode && <span className='text-red'>{t('fieldMandatory')}</span>}

						<label className="mt-4">{t('mechanographicCode')} *:</label>
						<input {...registerSchool("code", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.code && <span className='text-red'>{t('fieldMandatory')}</span>}
						<button onClick={() => setShowSearchSchool(true)} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary">{t('search')}</button>

						<label className="mt-4">{t('telephone')}:</label>
						<input {...registerSchool("telephone_number")} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />

						<label className="mt-4">{t('email')} *:</label>
						<input {...registerSchool("email", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						{formStateSchool.errors.email && <span className='text-red'>{t('fieldMandatory')}</span>}
						
						<label className="mt-4">{t('password')} *:</label>
						<div className="form-group relative flex items-center">
							<input type={passwordType} {...registerSchool("password", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Password" />
							{ 
								passwordType == "password" ?
									<FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
								:
									<FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleTogglePassword} />
							}
						</div>
						{formStateSchool.errors.password && <span className='text-red'>{t('fieldMandatory')}</span>}

						<label className="mt-4">{t('confirmPassword')} *:</label>
						<div className="form-group relative flex items-center">
							<input type={confirmPasswordType} {...registerSchool("confirmPassword", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" placeholder="Password" />
							{ 
								confirmPasswordType == "password" ?
									<FaEye className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
								:
									<FaEyeSlash className="absolute right-2 top-3 justify-around items-center z-10 cursor-pointer" onClick={handleToggleConfirmPassword} />
							}
						</div>
						{formStateSchool.errors.confirmPassword && <span className='text-red'>{t('fieldMandatory')}</span>}

						<div className='flex flex-col items-center mt-4'>
							<span className='text-xs'>{t('privacyLine1')}</span>
							<span className='text-xs'>{t('privacyLine2')}</span>
							<span className='text-xs'>{t('privacyLine3')}</span>
						</div>

						<div className='flex flex-row items-end space-x-1'>
							<input type="checkbox" {...registerSchool("privacy_consent", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateSchool.errors.privacy_consent && <div className='text-red'>{t('fieldMandatory')}</div>}
						</div>

						<div className='flex flex-col items-center mt-4'>
							<span className='text-xs'>{t('newsletterLine1')}</span>
							<span className='text-xs'>{t('newsletterLine2')}</span>
							<span className='text-xs'>{t('newsletterLine3')}</span>
							<span className='text-xs'>{t('newsletterLine4')}</span>
							<span className="text-xs">{t('newsletterLine5')}</span>
							<input type="checkbox" {...registerSchool("newsletter_consent")} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
						</div>

						<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4">
							{t('register')}
						</button>
					</form>

					{ error && error.length > 0 &&
							(
								<span className="text-primary mt-4">{error}</span>
							)
						}
					<button onClick={back} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2 uppercase">
						{t('cancel')}
					</button>
				</div>
				}
			</div>
		)

	if (typeSelected == 2)
		return (
			<div className="grid h-screen place-items-center">
				<div className="w-full flex flex-col items-center justify-center">
					<img alt="" src="userIcon.png" />
					<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmitIndividual(onSubmitIndividual)}>
						<div className='flex flex-row space-x-4'>
							<div className='flex flex-col'>
								<label className="mt-4">{t('serialCode')} *:</label>
								<input {...registerIndividual("activationCode", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.activationCode && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>

							<div className='flex flex-col'>
								<label className="mt-4">{t('businessName')} *:</label>
								<input {...registerIndividual("name", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.name && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>
						</div>

						<div className='flex flex-row space-x-4'>
							<div className='flex flex-col'>
								<label className="mt-4">{t('city')} *:</label>
								<input {...registerIndividual("city", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.city && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>

							<div className='flex flex-col'>
								<label className="mt-4">{t('province')} *:</label>
								<input {...registerIndividual("province", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.province && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>
						</div>

						<div className='flex flex-row space-x-4'>
							<div className='flex flex-col'>
								<label className="mt-4">{t('address')} *:</label>
								<input {...registerIndividual("address", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.address && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>

							<div className='flex flex-col'>
								<label className="mt-4">{t('zipCode')} *:</label>
								<input {...registerIndividual("zip_code", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.zip_code && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>
						</div>

						<div className='flex flex-row space-x-4'>
							<div className='flex flex-col'>
								<label className="mt-4">{t('region')} *:</label>
								<input {...registerIndividual("region", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
								{formStateIndividual.errors.region && <span className='text-red'>{t('fieldMandatory')}</span>}
							</div>

							<div className='flex flex-col'>
								<label className="mt-4">{t('telephone')}:</label>
								<input {...registerIndividual("telephone_number")} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							</div>
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">{t('email')} *:</label>
							<input {...registerIndividual("email", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.email && <span className='text-red'>{t('fieldMandatory')}</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">{t('password')} *:</label>
							<input type="password" {...registerIndividual("password", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.password && <span className='text-red'>{t('fieldMandatory')}</span>}
						</div>

						<div className='flex flex-col'>
							<label className="mt-4">{t('confirmPassword')} *:</label>
							<input type="password" {...registerIndividual("confirmPassword", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
							{formStateIndividual.errors.confirmPassword && <span className='text-red'>{t('fieldMandatory')}</span>}
						</div>

						{ error && error.length > 0 &&
							(
								<span className="text-primary">{error}</span>
							)
						}

						<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4 uppercase">
							{t('register')}
						</button>
						<button onClick={back} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2 uppercase">
							{t('cancel')}
						</button>
					</form>
				</div>
			</div>
		)
}

export default SignupPage;