export const getElementById = (array, id)  => {
    let cs = array.filter((elm) => Number(elm.id) === Number(id));

    if (cs.length > 0)
      return cs[0];

    return null;
}

export const parseLicenseJson = (data) => {
  let countries = "";
  let educationLevels = "";
  let lessonGroups = "";

  if (data == null)
    return ""

  let jsonData = JSON.parse(data)

  jsonData['countries'].forEach(element => {

    if (element != null && 'name' in element)
      countries += `${element.name} `;
  });

  jsonData['educationLevels'].forEach(element => {
    if (element != null && 'description' in element)
      educationLevels += `${element.description} `;
    else if (element != null && 'name' in element)
      educationLevels += `${element.name} `;
  });

  jsonData['lessonGroups'].forEach(element => {
    if (element != null && 'name' in element)
      lessonGroups += `${element.name} `;
  });

  return `${countries} - ${educationLevels} - ${lessonGroups}`;
}