import { useState, useRef, useEffect } from "react";
import { Rnd } from "react-rnd";
import CanvasDraw from "@win11react/react-canvas-draw";
import {
    FaArrowRight,
    FaPlay,
    FaPause,
    FaArrowLeft,
} from 'react-icons/fa';
import {
    TbRewindBackward10,
    TbRewindForward10
} from 'react-icons/tb'
import {
    FaArrowRotateRight,
    FaForwardStep,
    FaBackwardStep
} from 'react-icons/fa6';

import { config } from "../../config";
import 'reactjs-popup/dist/index.css';
import { useStores } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import { Action, ActionType, DataType, Widget, WidgetType } from "../../models/models";
import { useQuery } from "@tanstack/react-query";
import HashLoader from "react-spinners/HashLoader";
import { db } from "../../models/db";
import { useTranslation } from "react-i18next";

type NavigationProps = {
    side: string;
    isOpen: boolean;
    setControlBarOpen: any;
};

type ControlProps = {
    onReset: any;
    onPlay: any;
    onPause: any;
    onBack: any;
    onForward: any;
    onNextLesson: any;
    onPrevLesson: any;
    side: string;
    isOpen: boolean;
    setControlBarOpen: any;
};

function NavigationBar(props: NavigationProps) {
    const { auth } = useStores();
    const { t } = useTranslation();

    const currentLesson = auth.getLocalLesson();
    let navigate = useNavigate();
    const lessonsAvailable = auth.getLocalLessonGroup()?.lessons.sort((a, b) => (a.name < b.name) ? -1 : 1);
    const touchEnabled = useRef<boolean>(false);
    const [primaryColor, setPrimaryColor] = useState<string>("#EF7d00");

    useEffect(() => {

        if (currentLesson.color) {
            setPrimaryColor(currentLesson.color);
        }
    }, [])

    useEffect(() => {
        touchEnabled.current = false;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)
    }, [props.isOpen]);

    if (props.side == "none" || props.isOpen == false)
        return <></>;

    const goToLesson = lesson => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        auth.saveLocalLesson(lesson);

		navigate(0);
	}

    const back = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        if (auth.getLocalEducationLevel() == null)
            navigate('/user/personal-area');
        else
            navigate('/user/lesson-group-selection');
	}

    return (
        <>
            <div style={{ borderColor: primaryColor }} className={`${props.side == "right" ? "right-0 rounded-l-3xl border-l-2" : "left-0 rounded-r-3xl border-r-2"} absolute bottom-0 bg-white w-64 h-full flex flex-col border-t-2 border-b-2 justify-evenly items-start pr-3`}>
                { lessonsAvailable?.map((lesson, index) => (
                    <div onMouseDown={() => goToLesson(lesson)} onTouchStart={() => config.isMiri() ? goToLesson(lesson) : null} className="flex items-center rounded-md px-4 cursor-pointer">
                        { currentLesson.id == lesson.id ?
                            <span style={{ color: primaryColor, borderColor: primaryColor, borderWidth: 2 }} className="bg-white rounded-md w-6 h-6 text-center mr-2">{index+1}</span>
                        :
                            <span style={{ backgroundColor: primaryColor }} className="text-white rounded-md w-6 h-6 text-center mr-2">{index+1}</span>
                        }
                        <div className="flex flex-col">
                            <span className="text-sm text-black cursor-pointer">{lesson.title}</span>
                            <span className="text-sm text-black cursor-pointer">{lesson.description}</span>
                        </div>
                     </div>
                ))}

                <div onMouseDown={() => back()} onTouchStart={() => config.isMiri() ? back() : null} className="flex items-center rounded-md px-4 pb-12 cursor-pointer">
                    <span style={{ backgroundColor: primaryColor }} className="flex flex-row justify-center items-center text-white rounded-md w-6 h-6 text-center mr-2"><FaArrowLeft /></span>
                    <div className="flex flex-col">
                        <span className="text-xl font-bold text-black cursor-pointer uppercase">{t('goBack')}</span>
                    </div>
                </div>

            </div>
            <div onMouseDown={() => props.setControlBarOpen()} onTouchStart={() => config.isMiri() ? props.setControlBarOpen() : null} style={{ backgroundColor: primaryColor }} className={`${ props.side == "right" ? "right-64 rounded-l-3xl" : "left-64 rounded-r-3xl"} cursor-pointer absolute bottom-1/4 w-20 h-20 flex flex-col justify-center items-center text-white`}>
                {
                    props.side == "right" ?
                        <FaArrowRight />
                    :
                        <FaArrowLeft />
                }
            </div>
        </>
    );
}

function ControlBar(props: ControlProps) {
    const { auth } = useStores();
    const lesson = auth.getLocalLesson();
    const [primaryColor, setPrimaryColor] = useState<string>("#EF7d00");

    useEffect(() => {
        if (lesson.color) {
            setPrimaryColor(lesson.color);
        }
    }, [])

    if (props.side == "none" || props.isOpen == false)
        return <></>;

    return (
        <>
            <div style={{ borderColor: primaryColor }} className={`${props.side == "right" ? "absolute right-0" : "absolute left-0"} top-0 h-3/4 w-20 flex flex-col`}>
                <div style={{ borderColor: primaryColor }} className={`${props.side == "right" ? "rounded-l-3xl border-l-2" : "rounded-r-3xl border-r-2"} h-5/6 bg-white py-2 flex flex-col border-t-2 border-b-2 justify-evenly items-center space-y-2`}>
                    <div onMouseDown={() => props.onReset()} onTouchStart={() => config.isMiri() ? props.onReset() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <FaArrowRotateRight />
                    </div>
                    <div onMouseDown={() => props.onForward()} onTouchStart={() => config.isMiri() ? props.onForward() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <TbRewindForward10 />
                    </div>
                    <div onMouseDown={() => props.onBack()} onTouchStart={() => config.isMiri() ? props.onBack() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <TbRewindBackward10 />
                    </div>
                    <div onMouseDown={() => props.onPlay()} onTouchStart={() => config.isMiri() ? props.onPlay() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <FaPlay />
                    </div>
                    <div onMouseDown={() => props.onPause()} onTouchStart={() => config.isMiri() ? props.onPause() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <FaPause />
                    </div>
                    <div onMouseDown={() => props.onNextLesson()} onTouchStart={() => config.isMiri() ? props.onNextLesson() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <FaForwardStep />
                    </div>
                    <div onMouseDown={() => props.onPrevLesson()} onTouchStart={() => config.isMiri() ? props.onPrevLesson() : null} style={{ color: primaryColor }} className="cursor-pointer text-2xl">
                        <FaBackwardStep />
                    </div>
                </div>
                <div onMouseDown={() => props.setControlBarOpen()} onTouchStart={() => config.isMiri() ? props.setControlBarOpen() : null} style={{ backgroundColor: primaryColor }} className={`${props.side == "right" ? "rounded-l-3xl" : "rounded-r-3xl"} cursor-pointer mt-2 text-white w-20 h-1/6 flex flex-col justify-evenly items-center`}>
                    {
                        props.side == "right" ?
                            <FaArrowLeft />
                        :
                            <FaArrowRight />
                    }
                </div>
            </div>
        </>
    );
}

export default function NewLessonComposerPage() {
    const { t } = useTranslation();
    const { auth } = useStores();
    let navigate = useNavigate();
    const lesson = auth.getLocalLesson();

    const [dxControlBarOpen, setDxControlBarOpen] = useState<boolean>(true);
    const [sxControlBarOpen, setSxControlBarOpen] = useState<boolean>(true);

    const [screenSize, setScreenSize] = useState([0, 0]);
    const [screenRatioStr, setScreenRatioStr] = useState("48_9");
    const [screenRatio, setScreenRatio] = useState(48/9);
    const [widgets, setWidgets] = useState<Widget[]>([]);
    const [audios, setAudios] = useState<Widget[]>([]);
    const canvasRefs = useRef<any[]>([]);
    const [actions, setActions] = useState<Action[]>([]);
    const [firstActionElement, setFirstActionElement] = useState<Widget>();

    const [background, setBackground] = useState<Widget>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [brushColor, setBrushColor] = useState<string>("#FF0000");

    const screenRef = useRef(null);
    const vidRef = useRef(null);
    const audioRef = useRef(null);

    const touchEnabled = useRef<boolean>(true);
    const downloadedMediaItems = useRef<any>([]);

    const getCachedItems = async () => {
        downloadedMediaItems.current = await db.videoList.toArray();
        refetch();
    }

    useEffect(() => {
        getCachedItems();
    }, []);

    useEffect(() => {
        function updateSize() {
            repositionateWidgets();
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [widgets]);

    const { data, refetch } = useQuery({
        enabled: false,
        queryKey: ['1'],
        staleTime: Infinity,
        retry: 0,
        queryFn: () =>
          auth.getLesson(lesson.id).then(res => {
            parse(res.data.content);
            return res.data.content;
        }),
    });

    const lookupWidget = (list, id) => {
        return list.find((element) => element.id === id);
    }

    const playCallback = () => {
        if (audioRef.current)
            audioRef.current.play();
    }

    const playAudio = (audio) => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.removeEventListener("canplaythrough", playCallback);
        }

        if (audio.dataBlob != null) {
            audioRef.current = new Audio(audio.dataBlob);
        } else {
            audioRef.current = new Audio(audio.dataUrl);
        }

        audioRef.current.addEventListener("canplaythrough", playCallback);
    }

    const playSuccess = () => {
        let audioSuccess = new Audio("/audio/success.wav");

        audioSuccess.addEventListener("canplaythrough", audioSuccess.play);
    }

    const playFailure = () => {
        let audioFailure = new Audio("/audio/failure.wav");

        audioFailure.addEventListener("canplaythrough", audioFailure.play);
    }

    const performClickAction = (widgetId: string) => {
        if (!touchEnabled.current)
            return;

        setTimeout(() => {
            touchEnabled.current = true;
        }, 250)

        touchEnabled.current = false;

        let localWidgets = [...widgets];
        let isActioned: boolean = false;

        for (let i = 0; i < actions.length; i++) {
            let action = actions[i];

            if (action.actionType === ActionType.playAudio) {
                if (widgetId !== action.widget1)
                    continue;

                let audioWidget = lookupWidget(audios, action.widget2);

                playAudio(audioWidget);
            } else if (action.actionType === ActionType.show) {
                if (widgetId !== action.widget1)
                    continue;

                let widget2 = lookupWidget(localWidgets, action.widget2);
                widget2.visible = !widget2.visible;
            } else if (action.actionType === ActionType.showUnique) {
                if (widgetId !== action.widget1)
                    continue;

                let widget2 = lookupWidget(localWidgets, action.widget2);
                widget2.visible = !widget2.visible;

                if (widget2.visible == true) {
                    /* hide all other widgets */
                    for (let j = 0; j < actions.length; j++) {

                        if (actions[j].actionType === ActionType.show || 
                            actions[j].actionType === ActionType.showUnique) {

                                if (actions[j].widget2 === widget2.id)
                                    continue;

                                let myWidget = lookupWidget(localWidgets, actions[j].widget2);
                                myWidget.visible = false;
                        }
                    }
                }
            } else if (action.actionType === ActionType.match) {
                let widget1 = lookupWidget(localWidgets, action.widget1);
                let widget2 = lookupWidget(localWidgets, action.widget2);

                if (widgetId === action.widget1) {
                    if (firstActionElement) {
                        setFirstActionElement(null);
                        continue;
                    }

                    setFirstActionElement(widget1);
                }

                if (widgetId === action.widget2) {
                    if (!firstActionElement || firstActionElement.id !== action.widget1)
                        continue;

                    widget1.posX = widget2.posX;
                    widget1.posY = widget2.posY;
                    widget1.width = widget2.width;
                    widget1.height = widget2.height;

                    setFirstActionElement(null);

                    widget1.ref.updatePosition({
                        x: widget1.posX,
                        y: widget1.posY});

                    widget1.ref.updateSize({
                        width: widget1.width,
                        height: widget1.height
                    })

                    /* hack to make target widget invisible */
                    widget2.ref.updateSize({
                        width: 0,
                        height: 0});

                    isActioned = true;
                    playSuccess();
                }
            } else if (action.actionType === ActionType.truefalse) {
                if (widgetId === action.widget1 || widgetId === action.widget2) {
                    let myWidget = lookupWidget(localWidgets, widgetId);
                    /* action true, green overlay */
                    if (widgetId === action.widget1) {
                        myWidget.isRight = true;
                    }
                    /* action false, red overlay */
                    if (widgetId === action.widget2) {
                        myWidget.isFailed = true;
                    }
                }
                window.setTimeout(() => clearAnimations(), 1000);
                isActioned = true;
            }
        }
        if (firstActionElement != null && isActioned == false) {
            let myWidget1 = lookupWidget(localWidgets, widgetId);
            myWidget1.isFailed = true;

            window.setTimeout(() => clearAnimations(), 1000);

            setFirstActionElement(null);
            playFailure();
        }

        setWidgets(localWidgets);
    }

    const clearAnimations = () => {
        let localWidgets = [...widgets];

        for (let i = 0; i < localWidgets.length; i++) {
            localWidgets[i].isRight = false;
            localWidgets[i].isFailed = false;
        }

        setWidgets(localWidgets);
    }
 
    const resetPositions = () => {
        widgets.forEach((widget) => {
            widget.posX = widget.startingPosX;
            widget.posY = widget.startingPosY;

            widget.ref.updatePosition({
                x: widget.startingPosX,
                y: widget.startingPosY
            });

            widget.ref.updateSize({
                width: widget.startingWidth,
                height: widget.startingHeight
            });
        });

        canvasRefs.current?.forEach((canvas) => {
            canvas.eraseAll();
        });

        if (vidRef && vidRef.current) {
            vidRef.current.currentTime = 0;
        }
    }

    const repositionateWidgets = () => {
        let localWidgets = [...widgets];

        for (let index = 0; index < localWidgets.length; index++) {
            let widget = localWidgets[index];

            let currentWidth = screenSize[0] == 0 ? window.innerWidth : screenSize[0];
            let currentHeight = screenSize[1] == 0 ? window.innerWidth / (screenRatio) : screenSize[1];

            let newScreenWidth = window.innerWidth;
            let newScreenHeight = window.innerWidth / (screenRatio);

            if (currentWidth !== newScreenWidth || currentHeight !== newScreenHeight) {
                widget.startingPosX = (newScreenWidth * widget.startingPosX) / currentWidth;
                widget.startingPosY = (newScreenHeight * widget.startingPosY) / currentHeight;

                widget.posX = (newScreenWidth * widget.posX) / currentWidth;
                widget.posY = (newScreenHeight * widget.posY) / currentHeight;

                widget.width = (newScreenWidth * widget.width) / currentWidth;
                widget.height = (newScreenHeight * widget.height) / currentHeight;
            }

            widget.ref.updatePosition({
                x: widget.posX,
                y: widget.posY});
                
            widget.ref.updateSize({
                width: widget.width,
                height: widget.height
            })
        }

        setWidgets(localWidgets);
        setScreenSize([window.innerWidth, window.innerWidth / (screenRatio)]);
    }

    // backgroundDimension : parsedDimension = currentScreenDimension : x
    const scaleDown = (originalBackgroundDimension: number, currentDimension: number, screenDimension: number) => {
        return (currentDimension * screenDimension) / originalBackgroundDimension;
    }

    const parse = async (jsonBody) => {
        let localWidgets = [];
        let localAudios = [];
        let localActions = [];

        jsonBody = JSON.parse(jsonBody)['data'];

        let parsedRatio = jsonBody['ratio'];
        let screenRatio;

        if (parsedRatio == undefined || parsedRatio == "48_9") {
            setScreenRatioStr("48_9");
            setScreenRatio(48/9);
            screenRatio = 48/9;
        } else {
            setScreenRatioStr("16_9");
            setScreenRatio(16/9);
            screenRatio = 16/9;
        }

        let parsedB = jsonBody['background'];

        let originalBackgroundWidth = parsedB.width;
        let originalBackgroundHeight = originalBackgroundWidth / (screenRatio);
        let downloadedBlob = downloadedMediaItems.current.filter((item) => item.url == parsedB.dataUrl);

        let widgetB: Widget = {
            id: parsedB.id,
            startingPosX: parsedB.startingPosX,
            startingPosY: parsedB.startingPosY,
            posX: parsedB.posX,
            posY: parsedB.posY,
            startingWidth: window.innerWidth,
            startingHeight: window.innerWidth / (screenRatio),
            width: window.innerWidth,
            height: window.innerWidth / (screenRatio),
            visible: parsedB.visible,
            dataUrl: (parsedB.dataUrl && parsedB.dataUrl.startsWith("http")) ? parsedB.dataUrl : config.mediaEndpoint + parsedB.dataUrl,
            dataRaw: parsedB.dataRaw,
            dataBlob: (downloadedBlob != null && downloadedBlob.length > 0) ? URL.createObjectURL(downloadedBlob[0].data) : parsedB.dataBlob,
            dataName: parsedB.dataNAme,
            dataType: parsedB.dataType,
            loadedFromCache: downloadedBlob != null && downloadedBlob.length > 0,
            type: parsedB.type
        };
        setBackground(widgetB);

        for (let i = 0; i < jsonBody['widgets'].length; i++) {
            let parsedW = jsonBody['widgets'][i];
            let downloadedBlob = downloadedMediaItems.current.filter((item) => item.url == parsedW.dataUrl);

            let newHeight = parsedW.height;

            try {
                let img = new Image();
                img.src = parsedW.dataUrl;
                await img.decode();

                if (parsedW.width < img.naturalWidth) {
                    // newHeight : parsedW.width = img.naturalHeight : img.naturalWidth
                    newHeight = (parsedW.width * img.naturalHeight) / img.naturalWidth;
                } else {
                    parsedW.width = img.naturalWidth;
                    newHeight = img.naturalHeight;
                }
            } catch (e) {
                console.log(e, " Cannot load " + parsedW.dataUrl);
            }

            let widget: Widget = {
                id: parsedW.id,
                startingPosX: scaleDown(originalBackgroundWidth, parsedW.startingPosX, window.innerWidth),
                startingPosY: scaleDown(originalBackgroundHeight, parsedW.startingPosY, window.innerWidth / (screenRatio)),
                posX: scaleDown(originalBackgroundWidth, parsedW.posX, window.innerWidth),
                posY: scaleDown(originalBackgroundHeight, parsedW.posY, window.innerWidth / (screenRatio)),
                startingWidth: scaleDown(originalBackgroundWidth, parsedW.width, window.innerWidth),
                startingHeight: scaleDown(originalBackgroundHeight, newHeight, window.innerWidth / (screenRatio)),
                width: scaleDown(originalBackgroundWidth, parsedW.width, window.innerWidth),
                height: scaleDown(originalBackgroundHeight, newHeight, window.innerWidth / (screenRatio)),
                visible: true,
                dataUrl: (parsedW.dataUrl && parsedW.dataUrl.startsWith("http")) ? parsedW.dataUrl : config.mediaEndpoint + parsedW.dataUrl,
                dataRaw: parsedW.dataRaw,
                dataBlob: (downloadedBlob != null && downloadedBlob.length > 0) ? downloadedBlob[0] : parsedW.dataBlob,
                dataName: parsedW.dataNAme,
                dataType: parsedW.dataType,
                loadedFromCache: downloadedBlob != null && downloadedBlob.length > 0,
                type: parsedW.type
            };
            localWidgets.push(widget);
        }

        for (let i = 0; jsonBody['audios'] != null && i < jsonBody['audios'].length; i++) {
            let parsedA = jsonBody['audios'][i];
            let downloadedBlob = downloadedMediaItems.current.filter((item) => item.url == parsedA.dataUrl);

            let widget: Widget = {
                id: parsedA.id,
                startingPosX: 0,
                startingPosY: 0,
                posX: 0,
                posY: 0,
                width: 0,
                height: 0,
                startingWidth: 0,
                startingHeight: 0,
                visible: true,
                dataUrl: (parsedA.dataUrl && parsedA.dataUrl.startsWith("http")) ? parsedA.dataUrl : config.mediaEndpoint + parsedA.dataUrl,
                dataRaw: parsedA.dataRaw,
                dataBlob: (downloadedBlob != null && downloadedBlob.length > 0) ? URL.createObjectURL(downloadedBlob[0].data) : URL.createObjectURL(await fetch(parsedA.dataUrl).then(r => r.blob())),
                dataName: parsedA.dataNAme,
                dataType: parsedA.dataType,
                loadedFromCache: downloadedBlob != null && downloadedBlob.length > 0,
                type: parsedA.type
            };
            localAudios.push(widget);
        }

        for (let i = 0; i < jsonBody['actions'].length; i++) {
            let parsedW = jsonBody['actions'][i];

            let action: Action = {
                id: parsedW.id,
                widget1: parsedW.widget1,
                widget2: parsedW.widget2,
                actionType: parsedW.actionType
            };
    
            localActions.push(action);  
        }

        /* enforce visibility */
        for (let i = 0; i < localWidgets.length; i++) {
            for (let j = 0; j < localActions.length; j++) {
                if (localActions[j].widget2 == localWidgets[i].id &&
                    (localActions[j].actionType == ActionType.show ||
                        localActions[j].actionType == ActionType.showUnique
                    ))
                    localWidgets[i].visible = false;
            }
        }

        setScreenSize([window.innerWidth, window.innerWidth / (screenRatio)]);
        setWidgets(localWidgets);
        setAudios(localAudios);
        setActions(localActions);
        setIsLoading(false);
    }

    const onPlay = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        if (vidRef && vidRef.current)
            vidRef.current.play();
    }

    const onPause = () => {

		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;
        if (vidRef && vidRef.current)
            vidRef.current.pause();
    }

    const onBack = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        if (vidRef && vidRef.current)
            vidRef.current.currentTime=vidRef.current.currentTime-10;
    }

    const onForward = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        if (vidRef && vidRef.current)
            vidRef.current.currentTime=vidRef.current.currentTime+10;
    }

    const onNextLesson = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        const currentLesson = auth.getLocalLesson();
        const lessonsAvailable = auth.getLocalLessonGroup().lessons.sort((a, b) => (a.name < b.name) ? -1 : 1);

        let found = false;

        for (let i = 0; i < lessonsAvailable.length; i++) {
            if (found) {
                auth.saveLocalLesson(lessonsAvailable[i]);
                navigate(0);
                break;
            }
            if (lessonsAvailable[i].id == currentLesson.id) {
                found = true;
            }
        }
    }

    const onPrevLesson = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        const currentLesson = auth.getLocalLesson();
        const lessonsAvailable = auth.getLocalLessonGroup().lessons.sort((a, b) => (a.name < b.name) ? -1 : 1);

        let found = false;

        for (let i = lessonsAvailable.length; i > 0; i--) {
            if (found) {
                auth.saveLocalLesson(lessonsAvailable[i-1]);
                navigate(0);
                break;
            }
            if (lessonsAvailable[i-1].id == currentLesson.id) {
                found = true;
            }
        }
    }

    const setDxControlBarOpenCallback = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        setDxControlBarOpen(!dxControlBarOpen);
    }

    const setSxControlBarOpenCallback = () => {
		if (!touchEnabled.current)
			return;

		setTimeout(() => {
			touchEnabled.current = true;
		}, 250)

		touchEnabled.current = false;

        setSxControlBarOpen(!sxControlBarOpen);
    }

    const back = (evt) => navigate('/user/lesson-group-selection');

    if (isLoading)
        return <div className='grid h-screen place-items-center'>
            <HashLoader color="#EF7d00" />
            <button className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2" onMouseDown={back}>{t('back')}</button>
        </div>

    return (
        <div className={`w-full ${screenRatioStr == "48_9" ? "aspect-[48/9]" : "aspect-[16/9]"} relative overflow-x-hidden`} ref={screenRef}>
            {background && background.dataType === DataType.externalVideo && background.type === WidgetType.background && (
                (<iframe className="w-full h-full object-fill" src={background.dataUrl}></iframe>)
            )}
            { background && background.dataType === DataType.image && background.type === WidgetType.background && (
                (<img className="w-full h-full object-fill" src={(background.dataUrl && ! background.loadedFromCache) ? background.dataUrl : background.dataBlob} />)
            )}
            { background && background.dataType === DataType.video && background.type === WidgetType.background && (
                <video className="w-full h-full object-fill" autoPlay loop ref={vidRef}>
                    <source src={background.loadedFromCache ? background.dataBlob : background.dataUrl} type="video/mp4" />
                </video>
            )}                {
            widgets.map((widget, index) =>
                <Rnd
                    ref={ref => {
                        widget.ref = ref;}}
                    key={widget.id}
                    enableResizing={false}
                    disableDragging={true}
                    default={{
                        x: widget.posX,
                        y: widget.posY,
                        width: widget.width,
                        height: widget.height,
                    }}
                >
                    { widget.type === WidgetType.object ? (
                        <>
                            <img draggable={false} className={`${widget.isFailed ? "animate-shake" : ""} ${widget.visible ? "visible" : "invisible"} ${widget.id == firstActionElement?.id ? "outline outline-3 outline-primary rounded-2xl" : ""}`} src={widget.dataUrl ? widget.dataUrl : widget.dataBlob} onTouchStart={() => config.isMiri() ? performClickAction(widget.id) : null} onMouseDown={() => performClickAction(widget.id)} />
                            <div className={`${widget.isRight ? "opacity-100 duration-300 absolute top-1 left-1 z-10 flex justify-center items-center text-8xl text-green font-semibold" : "opacity-0 hidden"}`}> ✓</div>
                        </>
                    ) : widget.type == WidgetType.objectText ?
                        <>
                            <div className={`${widget.isFailed ? "animate-shake" : ""} ${widget.visible ? "visible" : "invisible"} ${widget.id == firstActionElement?.id ? "outline outline-3 outline-green rounded-2xl" : ""}bg-white flex-shrink-0 outline outline-2 outline-primary p-2 rounded-3xl text-center text-[0.5vw]`} onTouchStart={() => config.isMiri() ? performClickAction(widget.id) : null} onMouseDown={() => performClickAction(widget.id)}><span>{widget.dataRaw}</span></div>
                            <div className={`${widget.isRight ? "opacity-100 duration-300 absolute top-1 left-1 z-10 flex justify-center items-center text-8xl text-green font-semibold" : "opacity-0 hidden"}`}> ✓</div>
                        </>
                    : (
                        <div className="w-full h-full flex flex-col items-center">
                            <CanvasDraw className="rounded-2xl" style={{ background: "none" }}
                                canvasWidth="100%"
                                canvasHeight="100%"
                                brushColor={brushColor}
                                hideGrid={true}
                                ref={ref => {
                                    canvasRefs.current[index] = ref;
                                }}
                            />
                            <div className="mt-8 flex flex-row gap-2">
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(255, 165, 0, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(255, 165, 0, 0.8)")} style={{backgroundColor: 'rgba(255, 165, 0, 0.8)' }} className={`${brushColor == 'rgba(255, 165, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(135, 206, 250, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(135, 206, 250, 0.8)")} style={{backgroundColor: 'rgba(135, 206, 250, 0.8)' }} className={`${brushColor == 'rgba(135, 206, 250, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(0, 0, 255, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(0, 0, 255, 0.8)")} style={{backgroundColor: 'rgba(0, 0, 255, 0.8)' }} className={`${brushColor == 'rgba(0, 0, 255, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(255, 255, 0, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(255, 255, 0, 0.8)")} style={{backgroundColor: 'rgba(255, 255, 0, 0.8)' }} className={`${brushColor == 'rgba(255, 255, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(139, 69, 19, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(139, 69, 19, 0.8)")} style={{backgroundColor: 'rgba(139, 69, 19, 0.8)' }} className={`${brushColor == 'rgba(139, 69, 19, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(0, 0, 0, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(0, 0, 0, 0.8)")} style={{backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className={`${brushColor == 'rgba(0, 0, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(255, 192, 203, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(255, 192, 203, 0.8)")} style={{backgroundColor: 'rgba(255, 192, 203, 0.8)' }} className={`${brushColor == 'rgba(255, 192, 203, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(255, 0, 0, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(255, 0, 0, 0.8)")} style={{backgroundColor: 'rgba(255, 0, 0, 0.8)' }} className={`${brushColor == 'rgba(255, 0, 0, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(34, 139, 34, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(34, 139, 34, 0.8)")} style={{backgroundColor: 'rgba(34, 139, 34, 0.8)' }} className={`${brushColor == 'rgba(34, 139, 34, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                                <div onTouchStart={() => config.isMiri() ? setBrushColor("rgba(138, 43, 226, 0.8)") : null} onMouseDown={() => setBrushColor("rgba(138, 43, 226, 0.8)")} style={{backgroundColor: 'rgba(138, 43, 226, 0.8)' }} className={`${brushColor == 'rgba(138, 43, 226, 0.8)' ? "border-2 border-black" : ""} w-8 h-8 rounded-full cursor-pointer`}></div>
                            </div>
                        </div>
                    )}
                </Rnd>
            )}
            <ControlBar
                onReset={() => resetPositions()}
                onPlay={() => onPlay()}
                onPause={() => onPause()}
                onBack={() => onBack()}
                onForward={() => onForward()}
                onNextLesson={() => onNextLesson()}
                onPrevLesson={() => onPrevLesson()}
                side={config.isMiri() ? "left" : "none"}
                isOpen={sxControlBarOpen}
                setControlBarOpen={() => setSxControlBarOpenCallback()}
            />
            <NavigationBar 
                side={config.isMiri() ? "left": "none"}
                isOpen={!sxControlBarOpen}
                setControlBarOpen={() => setSxControlBarOpenCallback()}
            />
            <ControlBar
                onReset={() => resetPositions()}
                onPlay={() => onPlay()}
                onPause={() => onPause()}
                onBack={() => onBack()}
                onForward={() => onForward()}
                onNextLesson={() => onNextLesson()}
                onPrevLesson={() => onPrevLesson()}
                side={"right"}
                isOpen={dxControlBarOpen}
                setControlBarOpen={() => setDxControlBarOpenCallback()}
            />
            <NavigationBar
                side={"right"}
                isOpen={!dxControlBarOpen}
                setControlBarOpen={() => setDxControlBarOpenCallback()}
            />
        </div>
    );
}
