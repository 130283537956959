import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import { useStores } from "../../../stores/store";
import { useTranslation } from "react-i18next";

export default function AdminUserEditPage() {
    const { auth } = useStores();
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    
    const [isDataError, setIsDataError] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [user, setUser] = useState(null);
    
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    let navigate = useNavigate();
    const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm();
    const onSubmit = data => postData(data);

    const watchUserType = watch("type");

    useEffect(() => {

        auth.getCountries().then((resC) => {
            setCountries(resC.data);
        }).catch((error) => {
            setIsDataLoading(false);
            setIsDataError(true);
        });

        if (id === "new") {
            setIsPasswordShown(true);
        } else {
            setIsDataLoading(true);
            setIsDataError(false);
        
            auth.getCountries().then((resC) => {
                setCountries(resC.data);

                auth.getUser(id).then((resUser) => {
                    setUser(resUser.data);
                    setIsDataLoading(false);
                }).catch((error) => {
                    setIsDataLoading(false);
                    setIsDataError(true);
                });
            }).catch((error) => {
            setIsDataLoading(false);
            setIsDataError(true);
            });
        }
    }, [])

    useEffect(() => {
        setValue('name', user?.name, { shouldValidate: true })
        setValue('surname', user?.surname, { shouldValidate: true })
        setValue('email', user?.email, { shouldValidate: true })
        setValue('type', user?.type, { shouldValidate: true })
        setValue('fk_country', user?.fk_country, { shouldValidate: true })
    }, [user]);

    const postData = async (data) => {
        if (isPasswordShown) {
            if (password.length == 0 || confirmPassword.length == 0) {
                setError(t('passwordNotValid'));
                return;
            }
            if (password != confirmPassword) {
                setError(t('passwordNotValid'));
                return;
            }
        }
        if (id !== "new") {
            await auth.editUser(id, data);
            if (isPasswordShown)
                await auth.editPassword(id, password);
        } else {
            let resp = await auth.addUser(data);
            await auth.editPassword(resp.data.id, password);
        }

        navigate(`/admin/users/`);
    }

    if (isDataLoading)
        return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

    if (isDataError)
        return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-row justify-center">
                {id == "new" ? <span className="text-4xl">{t('newUser')}</span> : <span className="text-4xl">{t('editUser')}</span>}
            </div>
            <div className="w-full flex flex-row justify-center">
                <form className="w-1/3 bg-white flex flex-col gap-4 p-8 rounded-3xl shadow-xl" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('name')}</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("name", { required: "Recipe name is required" })} />
                        {errors.name && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('surname')}</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("surname", { required: true })} />
                        {errors.surname && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('email')}</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("email", { required: true })} />
                        {errors.email && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2">{t('type')}</label>
                        <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("type", { required: true })}>
                            <option value="1">Admin</option>
                            <option value="4">Demo</option>
                            <option value="5">Partner</option>
                        </select>
                        {errors.type && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                    </div>
                    { watchUserType == "5" && /* partner */
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">{t('folder')}</label>
                            <select className="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" {...register("fk_country", { required: true })}>
                                <option value="">{t('none')}</option>
                                {
                                    countries?.map((country) => (
                                        <option value={country.id}>{country.name}</option>
                                    ))
                                }
                            </select>
                            {errors.fk_country && isSubmitted && <span className="text-red">{t('fieldMandatory')}</span>}
                        </div>
                    }
                    { id !== "new" && <button className="border-2 border-primary hover:bg-blue-700 text-primary font-bold py-2 px-4 rounded-full" onClick={(e) => { e.preventDefault(); setIsPasswordShown(!isPasswordShown)}}>{t('modifyPassword')}</button>}
                    { isPasswordShown &&
                        <>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">{t('password')}</label>
                                <input type="password" onChange={e => setPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">{t('confirmPassword')}</label>
                                <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                        </>
                    }
                    { error.length > 0 && <span className="text-red">{error}</span> }
                    <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" type="submit">{t('save')}</button>
                    <button className="border-2 border-primary text-primary font-bold py-2 px-4 rounded-full" onClick={() => navigate('/admin/users')}>{t('cancel')}</button>
                </form>
            </div>
        </div>
    );
}
