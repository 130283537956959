import { Navigate } from "react-router-dom";
import { useStores } from "../../../stores/store";
import { Container } from "../container";

const SchoolRoute = ({ Component }) => {
	const { auth } = useStores();

    return auth.isSchool() ? 
        <Container>
            <Component />
        </Container>
    :
        <Navigate to="/page401" />;
};
export default SchoolRoute;