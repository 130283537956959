import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../../stores/store'
import { Link } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
import { CSVLink } from 'react-csv';
import { FaEdit } from 'react-icons/fa';
import { getElementById } from '../../common/functions';
import { Table } from '../../common/table';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

function AdminUsersPage() {
  const { auth } = useStores();
  const { t, i18n } = useTranslation();

  const searchKey = useRef<string>("");
  const searchRef = useRef(null);

  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentType, setCurrentType] = useState(1);
  const [countries, setCountries] = useState([]);
  const [isFilterError, setIsFilterError] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    searchKey.current = localStorage.getItem('users_searchKey');
    setCurrentType(localStorage.getItem('users_type') ? +localStorage.getItem('users_type') : 1);
    setCurrentCountry(localStorage.getItem('users_country') ? +localStorage.getItem('users_country') : null);

    setIsFilterLoading(true);
    setIsFilterError(false);

    auth.getCountries().then((res) => {
      setCountries(res.data);
      if (res.data.length > 0) {
        setCurrentCountry(res.data[0]);
      }
      setIsFilterLoading(false);
    }).catch((error) => {
      setIsFilterLoading(false);
      setIsFilterError(true);
    });
  }, [])

  useEffect(() => {
    getUsers();
  }, [currentCountry, currentType])

  const getUsers = () => {
    if (currentCountry == null)
      return;

    setIsDataLoading(true);
    setIsDataError(false);

    auth.getUsers(currentType, searchKey.current).then((res) => {
      setUsers(res.data);
      setIsDataLoading(false);
    }).catch((error) => {
      setIsDataLoading(false);
      setIsDataError(true);
    });
  }
    
  const userColumns = React.useMemo(
      () => [
      {
          Header: t('users'),
          columns: [
            {
              Header: t('modify'),
              accessor: "id",
              Cell: ({ cell }) => (
                <Link to={
                    `/admin/users/${cell.row.values.id}`}>
                  <FaEdit />
                </Link>
              )
            },
            {
                Header: t('companyName'),
                accessor: 'pretty_name',
            },
            {
              Header: t('type'),
              accessor: 'type',
              Cell: ({ cell }) => (
                cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
                cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
                cell.row.values.type == 3 ? <span>{t('school')}</span> : 
                cell.row.values.type == 4 ? <span>{t('demo')}</span> :
                cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
              )
            },
            {
                Header: t('school'),
                accessor: 'school.name',
            },
            {
              Header: t('mechanographicCode'),
              accessor: 'school.code',
            },
          ],
      },
      {
        Header: "Login",
        columns: [
        {
            Header: t('email'),
            accessor: 'email',
        },
        {
          Header: t('active'),
          accessor: "is_old",
          Cell: ({ cell }) => (
            cell.row.values.is_old ? t('no') : t('yes')
          )
        },
        ],
    },
    ],
      []
  );

  const schoolColumns = React.useMemo(
    () => [
    {
        Header: t('users'),
        columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/users/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: t('school'),
              accessor: 'pretty_name',
          },
          {
            Header: t('type'),
            accessor: 'type',
            Cell: ({ cell }) => (
              cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
              cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
              cell.row.values.type == 3 ? <span>{t('school')}</span> : 
              cell.row.values.type == 4 ? <span>{t('demo')}</span> :
              cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
            )
          },
          {
            Header: t('mechanographicCode'),
            accessor: 'school.code',
          },
        ],
    },
    {
      Header: "Login",
      columns: [
      {
          Header: t('email'),
          accessor: 'email',
      },
      {
        Header: t('active'),
        accessor: "is_old",
        Cell: ({ cell }) => (
          cell.row.values.is_old ? t('no') : t('yes')
        )
      },
      ],
  },
  ],
    []
  );

  const adminColumns = React.useMemo(
    () => [
    {
        Header: t('users'),
        columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/users/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: t('name'),
              accessor: 'pretty_name',
          },
          {
            Header: t('type'),
            accessor: 'type',
            Cell: ({ cell }) => (
              cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
              cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
              cell.row.values.type == 3 ? <span>{t('school')}</span> : 
              cell.row.values.type == 4 ? <span>{t('demo')}</span> :
              cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
            )
          },
        ],
    },
    {
      Header: "Login",
      columns: [
      {
          Header: t('email'),
          accessor: 'email',
      },
      {
        Header: t('active'),
        accessor: "is_old",
        Cell: ({ cell }) => (
          cell.row.values.is_old ? t('no') : t('yes')
        )
      },
      ],
  },
  ],
    []
  );

  const partnerColumns = React.useMemo(
    () => [
    {
        Header: t('users'),
        columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/users/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: t('name'),
              accessor: 'pretty_name',
          },
          {
            Header: t('folder'),
            accessor: 'fk_country_name',
          },
          {
            Header: t('type'),
            accessor: 'type',
            Cell: ({ cell }) => (
              cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
              cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
              cell.row.values.type == 3 ? <span>{t('school')}</span> : 
              cell.row.values.type == 4 ? <span>{t('demo')}</span> :
              cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
            )
          },
        ],
    },
    {
      Header: "Login",
      columns: [
      {
          Header: t('email'),
          accessor: 'email',
      },
      {
        Header: t('active'),
        accessor: "is_old",
        Cell: ({ cell }) => (
          cell.row.values.is_old ? t('no') : t('yes')
        )
      },
      ],
  },
  ],
    []
  );

  const demoColumns = React.useMemo(
    () => [
    {
        Header: t('users'),
        columns: [
          {
            Header: t('modify'),
            accessor: "id",
            Cell: ({ cell }) => (
              <Link to={
                  `/admin/users/${cell.row.values.id}`}>
                <FaEdit />
              </Link>
            )
          },
          {
              Header: t('name'),
              accessor: 'pretty_name',
          },
          {
            Header: t('type'),
            accessor: 'type',
            Cell: ({ cell }) => (
              cell.row.values.type == 1 ? <span>{t('admin')}</span> : 
              cell.row.values.type == 2 ? <span>{t('professor')}</span> : 
              cell.row.values.type == 3 ? <span>{t('school')}</span> : 
              cell.row.values.type == 4 ? <span>{t('demo')}</span> :
              cell.row.values.type == 5 ? <span>{t('partner')}</span> : <span></span>
            )
          },
        ],
    },
    {
      Header: "Login",
      columns: [
      {
          Header: t('email'),
          accessor: 'email',
      },
      {
        Header: t('active'),
        accessor: "is_old",
        Cell: ({ cell }) => (
          cell.row.values.is_old ? t('no') : t('yes')
        )
      },
      ],
  },
  ],
    []
  );


  const search = event => {
    if (event.target.value && event.target.value.length < 4)
      return;

    localStorage.setItem('users_searchKey', event.target.value);

    searchKey.current = event.target.value;
    getUsers();
  }

  const handleCountryChange = ({ target }) => {
    localStorage.setItem('users_country', target.value);

    let country = getElementById(countries, target.value);
    setCurrentCountry(country);
  }

  const handleTypeChange = ({ target }) => {
    localStorage.setItem('users_type', target.value);
    setCurrentType(target.value);
  }

  if (isFilterLoading)
    return <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div>

  if (isFilterError)
    return <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div>

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-start">
        <span className="text-4xl">{t('users')}</span>
      </div>

      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-2">
          <select onChange={handleCountryChange} defaultValue={currentCountry} className="bg-primary text-white rounded-full p-4 cursor-pointer">
            {countries?.map(({ id, name }, index) => <option value={id} >{name}</option>)}
          </select>
          <select onChange={handleTypeChange} defaultValue={currentType} className="bg-primary text-white rounded-full p-4 cursor-pointer">
            <option value="1">{t('admin')}</option>
            <option value="5">{t('partner')}</option>
            <option value="3">{t('school')}</option>
            <option value="2">{t('professor')}</option>
            <option value="4">{t('demo')}</option>
          </select>
          <input onChange={search} ref={searchRef} defaultValue={searchKey.current} placeholder={t('search')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          <CSVLink className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" data={users} filename={`exportdata_${Moment().format("DD-MM-YYYY")}.csv`}>{t('export')}</CSVLink>
        </div>
        <Link className="bg-primary hover:bg-blue-700 text-white font-bold p-4 rounded-full" to="/admin/users/new">{t('add')}</Link>

      </div>

      {isDataLoading && <div className='grid h-screen place-items-center'><HashLoader color="#EF7d00" /></div> }

      {isDataError && <div className='grid h-screen place-items-center'>{t('cannotLoadPage')}</div> }

      {!isDataError && !isDataLoading && currentType == 2 && <Table columns={userColumns} data={users} /> }
      {!isDataError && !isDataLoading && currentType == 1 && <Table columns={adminColumns} data={users} /> }
      {!isDataError && !isDataLoading && currentType == 5 && <Table columns={partnerColumns} data={users} /> }
      {!isDataError && !isDataLoading && currentType == 3 && <Table columns={schoolColumns} data={users} /> }
      {!isDataError && !isDataLoading && currentType == 4 && <Table columns={demoColumns} data={users} /> }
    </div>
  )
}

export default AdminUsersPage
