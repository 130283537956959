import { useStores } from '../stores/store';
import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function PasswordRecovery2Page(props) {
	const { t } = useTranslation();
	const { auth } = useStores();
	const [error, setError] = useState<string>("");
	const { register, handleSubmit, formState: { errors } } = useForm();
	// eslint-disable-next-line
	const [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();

	const onSubmit = async data => {
		if (data.password !== data.confirmPassword) {
			setError(t('passwordsDoNotMatch'));
			return;
		}

		if (data.password.length < 8) {
			setError(t('passwordAtLeast8Chars'));
			return;
		}

		const res = await auth.passwordReset(searchParams.get('code'), data.password);

		if (!res || res.error) {
			setError(`${t('resetFailed')}: ${res.msg}`);
		} else {
			if (res.success)
				navigate('/');
			else
				setError(`${t('resetFailed')}: ${res.msg}`)
		}
	};

	const back = evt => {
		navigate(`/`);
	};

	return (
		<div className="grid h-screen place-items-center">
			<div className="w-full flex flex-col items-center justify-center">
				<form className="border-2 border-primary p-2 rounded-2xl flex flex-col items-center justify-center" onSubmit={handleSubmit(onSubmit)}>
					<label className="mt-4">{t('password')}</label>
					<input type="password" {...register("password", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
					{errors.passwordRequired && <span>{t('fieldMandatory')}</span>}

					<label className="mt-4">{t('confirmPassword')}</label>
					<input type="password" {...register("confirmPassword", { required: true })} className="form-control mt-1 bg-primary-light rounded-lg p-1 outline-2 outline-primary" />
					{errors.confirmPasswordRequired && <span>{t('fieldMandatory')}</span>}

					<button type="submit" className="bg-primary text-white text-center rounded-lg p-2 mt-4 uppercase">
						{t('reset')}
					</button>
				</form>

				{ error && error.length > 0 &&
					(
						<span className="text-primary mt-4">{error}</span>
					)
				}
				<button onClick={back} className="border-2 border-primary text-primary text-center rounded-lg p-2 mt-2">
					{t('cancel')}
				</button>
			</div>
		</div>
	)
}

export default PasswordRecovery2Page;