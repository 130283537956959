export function Container(props: any) {
  return (
    <div className="w-full overflow-x-clip">
        <div className="min-h-screen bg-lightblue text-gray-900">
          <main className="px-4 sm:px-6 lg:px-8 pt-4">
            <div className="mt-4">
              {props.children}
            </div>
          </main>
        </div>
    </div>
  );
}