import Dexie, { Table } from 'dexie';
import { MediaDbItem } from './MediaDbItem';

export class MediaDB extends Dexie {
    videoList!: Table<MediaDbItem>;
    constructor() {
        super('MediaDB');
        this.version(1).stores({
            videoList: 'url, data'
        });
    }
}

export const db = new MediaDB();